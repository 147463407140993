/**
 * 通用颜色
 */
.BackTop__content___2Ozb8 {
  position: fixed;
  z-index: -1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: rgba(72, 72, 72, 0);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAPFBMVEUAAAD////////////////////////////////////////////////////////////////////////////YSWgTAAAAE3RSTlMAAAEQESA/QI+Qnp+gzc7P3t/+vcbpmwAAAcBJREFUeNrt2ctugzAQQFG7ToppWgjm//+1i6aPgMeAW8kz6b1LJCQOBGxlnCMiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiL6RV0/zfN4ORtnhDjfisG0Y5y/GsNjOCxL7h3zPITHcFiVrB02JTmHRUneYU8iOaxJZIctSclhSVJ22JFsOaxIth02JHscFiT7HPolex3aJfsduiVHHJolxxx6JUcdWiXHHTolNQ6NkjqHPkmtQ5uk3qFLIjjGzQPKJJIjLA6kk26J6HBLiBclXrVjBXFe8zOJomMNESWx/SM5y44MRJKkrjnkVXbkIJKkbw4ZZUcWIkiuzSEFRx6SlySFkO+BjnCx/jQohEyFT6l0sRnJ0BzSF5YE8a6vJS/NIV1haZN/PktJUjC+jvISXXgP7iUpatiiDOJWo/RC/5SkNxXbxhDT530NbjfE+dBL57XbplyGlK6x2/g0Lz+x/nbes/b/gZ26tQIIECBA/gXkCQgQIECAAAECBAgQIECAAPmATNpmILWQXtsMpDLfqZuB/MUjUTEDqX0k+mYgtRKFM5BaipkZCBERERERERERERERERERERERERERERFR494BUmKpL5dD2a4AAAAASUVORK5CYII=);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.BackTop__content___2Ozb8:hover {
  background-color: rgba(72, 72, 72, 0.6);
}
.BackTop__contentPc___39Txy {
  bottom: 80px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: 466px;
}
.BackTop__contentMobile___sMmFk {
  right: 24px;
  bottom: 24px;
  width: 40px;
  height: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
.BackTop__contentMobile___sMmFk:hover {
  background-color: rgba(72, 72, 72, 0.2);
}
.BackTop__show___37ng0 {
  z-index: 666;
  background-color: rgba(72, 72, 72, 0.2);
}
