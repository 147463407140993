/**
 * 通用颜色
 */
.Footer__content___ed4BR {
  width: 100%;
  min-width: 1032px;
  background: #ffffff;
}
.Footer__contentMain___2f3ND {
  width: 1032px;
  margin: 0 auto;
  padding: 80px 0 78px;
  border-top: 1px solid #efedec;
}
.Footer__contentMain___2f3ND .Footer__nav___3G0DM {
  margin-bottom: 40px;
}
.Footer__contentMain___2f3ND .Footer__nav___3G0DM .Footer__linkItem___1UZU1 {
  display: inline-block;
  margin-right: 20px;
  color: #484848;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  vertical-align: middle;
}
.Footer__contentMain___2f3ND a {
  text-decoration: none;
  outline: none;
}
.Footer__contentMain___2f3ND a:active,
.Footer__contentMain___2f3ND a:hover {
  color: #ff8576;
  text-decoration: underline;
}
.Footer__contentMain___2f3ND .Footer__companyBox___EDexO {
  height: 42px;
}
.Footer__contentMain___2f3ND .Footer__company___An2Ou {
  width: 200%;
  color: #484848;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.Footer__contentMain___2f3ND .Footer__company___An2Ou .Footer__icpItem___S3FRY {
  margin-right: 20px;
  color: #484848 !important;
}
.Footer__contentMain___2f3ND .Footer__partners___3VUt4 {
  height: 28px;
  margin-bottom: 54px;
  color: #b0a9a3;
  font-size: 20px;
  line-height: 28px;
}
.Footer__contentMain___2f3ND .Footer__partners___3VUt4 .Footer__partnerStr___Erjf2 {
  width: 200%;
  white-space: pre-line;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.Footer__hasPartners___2yCpd {
  padding: 50px 0 78px;
}
